<template>
  <ValidationObserver>
    <form @submit.prevent="saveData">
      <b-card title="Update Pembongkaran">
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Title</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.name"
              placeholder="title"
              required
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Price</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.price"
              type="number"
              placeholder=""
              required
            />
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"],
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      category: [],
      subcategory: [],
      required,
      isLoading: false,
      formPayload: {},
      image_thumbnail: "",
      bannerImage: "",
      fileVendorImage: [],
      filesThumbnail: [],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        [false, false],
        [false],
        [false],
      ],
      snowOption: {
        theme: "snow",
      },
      validations: "",
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  watch: {},

  created() {
    this.getData();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        form.append(key, this.formPayload[key]);
      }
      return form;
    },
    getData() {
        const params = this.$route.params.slug;
        this.$http.get("/admin/pembongkaran/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
        })
        .catch((error) => {
        });
    },
    saveData() {
      const slugs = this.$route.params.slug
      var form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");
      this.$http
        .post(`/admin/pembongkaran/${slugs}`, form, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Pembongkaran Updated!");
          this.$router.push({ name: "building-material-pembongkaran" });
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(this, "Nama", this.validations.name.join(" "));
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
